import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';

// Initialize Firebase
const app = initializeApp({
  apiKey: 'AIzaSyDzuF1yAKRBo27_bg-e-8yXULADLntzFp0',
  authDomain: 'cryptolaos-e089c.firebaseapp.com',
  projectId: 'cryptolaos-e089c',
  storageBucket: 'cryptolaos-e089c.appspot.com',
  messagingSenderId: '186772564071',
  appId: '1:186772564071:web:8fb0c17cf002b98257ccc2',
  measurementId: 'G-HXDQ9HN8G7'
});

// Firebase storage reference
const storage = getStorage(app);
export default storage;
