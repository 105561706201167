import * as React from 'react';

const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

const getExtension = type => {
  if (type === 'image/jpeg') {
    return 'jpeg';
  } else if (type === 'image/png') {
    return 'png';
  } else if (type === 'image/gif') {
    return 'gif';
  } else if (type === 'image/jpg') {
    return 'jpg';
  } else if (type === 'application/pdf') {
    return 'pdf';
  } else {
    return '';
  }
};

const useFilePicker = ({
  inputId,
  fileName,
  initialFiles,
  multiple = true,
  customPickFiles,
  customRemoveFile
}) => {
  const [files, setFiles] = React.useState(initialFiles || []);

  const pickFile = (event, name) => {
    const selectedFiles = event.target.files;

    let file =
      selectedFiles && selectedFiles.length && selectedFiles.length > 0
        ? selectedFiles[0]
        : null;
    if (!file) {
      return;
    }

    if (name) {
      file = new File(
        [file],
        `${fileName || name.replace('C:\\fakepath\\', '')}${
          fileName ? '.' + getExtension(file.type) : ''
        }`,
        {
          type: file.type,
          lastModified: file.lastModified
        }
      );
    }

    Object.assign(file, {
      preview: URL.createObjectURL(file),
      formattedSize: formatBytes(file.size)
    });

    if (multiple) {
      setFiles([...files.slice(), ...[file]]);
      if (customPickFiles) {
        customPickFiles([...files.slice(), ...[file]]);
      }
    } else {
      setFiles([...[file]]);
      if (customPickFiles) {
        customPickFiles([...[file]]);
      }
    }

    event.target.value = '';
  };

  const removeFile = index => {
    const input = document.getElementById(inputId);
    if (input) {
      input.value = null;
    }
    setFiles(
      files.filter((_, ind) => {
        return ind !== index;
      })
    );
    if (customRemoveFile) {
      customRemoveFile(index);
    }
  };

  const removeAllFiles = () => setFiles([]);

  const pickDropFiles = droppedFile => {
    const newFiles = droppedFile.map(file => ({
      ...file,
      preview: URL.createObjectURL(file),
      formattedSize: formatBytes(file.size),
      type: file.type,
      name: file.name
    }));
    if (multiple) {
      setFiles([...files.slice(), ...newFiles]);
    } else {
      setFiles([...newFiles]);
    }
  };

  const state = {
    files
  };

  return {
    state,
    pickFile,
    removeFile,
    removeAllFiles,
    pickDropFiles
  };
};

export default useFilePicker;
