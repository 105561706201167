import * as React from 'react';
import { Button } from 'semantic-ui-react';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

import CollapsibleSection from '../../components/CollapsibleSection';
import useFilePicker from '../../hooks/useFilePicker';
import storage from '../../firebase.config';

const Upload = () => {
  const inputRef = React.useRef();
  const {
    state: { files },
    pickFile,
    removeAllFiles
  } = useFilePicker({
    inputId: 'file_laos',
    multiple: false
  });

  const [loading, setLoading] = React.useState(false);
  const [url, setUrl] = React.useState('');

  const handleUpload = () => {
    setLoading(true);
    const file = files[0];
    const storageRef = ref(storage, `/files/${file.name}`);

    // progress can be paused and resumed. It also exposes progress updates.
    // Receives the storage reference and the file to upload.
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      'state_changed',
      snapshot => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );

        // update progress
      },
      err => {
        console.log(err);
        setLoading(false);
      },
      () => {
        // download url
        getDownloadURL(uploadTask.snapshot.ref)
          .then(url => {
            console.log(url);
            setLoading(false);
            setUrl(url);
            removeAllFiles();
          })
          .catch(() => {
            setLoading(false);
          });
      }
    );
  };

  return (
    <div className="container-full-page mt-settings">
      <CollapsibleSection name="file_upload" label="File Upload">
        {(!files || files.length === 0) && !url && (
          <>
            <Button
              content="Choose File"
              labelPosition="left"
              icon="file"
              onClick={() => {
                if (inputRef) {
                  inputRef.current.click();
                }
              }}
            />
            <input
              ref={inputRef}
              type="file"
              hidden
              name="file_laos"
              onChange={pickFile}
              multiple={false}
            />
          </>
        )}
        {files.length > 0 && !url && (
          <>
            {' '}
            <p>You've selected {files.length} file</p>
            <div style={{ display: 'flex' }}>
              <Button
                icon="trash"
                content="Upload Another"
                labelPosition="left"
                onClick={removeAllFiles}
                disabled={loading}
              />
              <Button
                icon="upload"
                content="Continue"
                labelPosition="left"
                onClick={handleUpload}
                loading={loading}
              />
            </div>
          </>
        )}
        {url && (
          <>
            <p>
              Here is the URL - <span style={{ color: 'blue' }}>{url}</span>
            </p>
            <Button
              icon="upload"
              content="Upload Another"
              labelPosition="left"
              onClick={() => setUrl('')}
              loading={loading}
            />
          </>
        )}
      </CollapsibleSection>
    </div>
  );
};

export default Upload;
