import React from 'react';
import logo from './logo.jpg';
import './index.css';

const Logo = () => (
  <div className="logo">
    <img src={logo} alt="Money Laos" />
  </div>
);

export default Logo;
